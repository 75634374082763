import { Injectable } from '@angular/core';
import { createEffect, ofType, tapResult } from '@ngneat/effects';
import { map, switchMap, tap } from 'rxjs';

import { ISCategoriesForProject } from '@ess/integrated-search/shared/utils';

import { IntegratedSearchProjectsDataAccessCategoriesActions as CategoriesActions } from './integrated-search-projects-data-access-categories.actions';
import { IntegratedSearchProjectsDataAccessCategoriesRepository } from './integrated-search-projects-data-access-categories.repository';

import { IntegratedSearchProjectsDataAccessService } from '../services';
import { IntegratedSearchProjectsDataAccessActions as ProjectsActions } from '../state';

@Injectable()
export class IntegratedSearchProjectsDataAccessCategoriesEffects {
  loadCategories$ = createEffect(
    (actions) =>
      actions.pipe(
        ofType(CategoriesActions.loadCategories, ProjectsActions.setActiveProjectSuccess),
        map(({ projectId }) =>
          projectId ? CategoriesActions.sendCategoriesRequest({ projectId }) : CategoriesActions.projectNotFound(),
        ),
      ),
    { dispatch: true },
  );

  refreshCategories$ = createEffect(
    ($) =>
      $.pipe(
        ofType(CategoriesActions.refreshCategories),
        map(() => this.__repo.currentProjectId),
        map((projectId) =>
          projectId
            ? CategoriesActions.sendCategoriesRequest({
                projectId,
                refresh: true,
              })
            : CategoriesActions.projectNotFound(),
        ),
      ),
    { dispatch: true },
  );

  sendCategoriesRequest$ = createEffect(($) =>
    $.pipe(
      ofType(CategoriesActions.sendCategoriesRequest),
      tap(({ refresh }) => this.__repo.setLoading(refresh)),
      switchMap(({ projectId }) =>
        this.__projectsService.getCategoriesForProject(projectId!).pipe(
          tapResult(
            (categoriesList: ISCategoriesForProject) =>
              this.__repo.saveCategories(
                projectId!,
                categoriesList.items.filter((category) => category.active),
              ),
            () => this.__repo.handleLoadCategoriesFail(),
          ),
        ),
      ),
    ),
  );

  projectNotFound$ = createEffect(($) =>
    $.pipe(
      ofType(CategoriesActions.projectNotFound),
      tap(() => this.__repo.resetStore()),
    ),
  );

  constructor(
    private readonly __projectsService: IntegratedSearchProjectsDataAccessService,
    private readonly __repo: IntegratedSearchProjectsDataAccessCategoriesRepository,
  ) {}
}
