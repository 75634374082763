import { Injectable } from '@angular/core';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { deleteAllEntities, getAllEntities, selectAllEntities, setEntities, withEntities } from '@ngneat/elf-entities';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, shareReplay } from 'rxjs';

import { ISCategoryDetails, ISStoreEnum } from '@ess/integrated-search/shared/utils';
import { SharedEntitiesStore } from '@ess/shared/utils/models';

interface StoreProps {
  loading: boolean;
  refreshing: boolean;
  error: string | null;
  currentProjectId: number | null;
}

type Store = SharedEntitiesStore<ISCategoryDetails, number, StoreProps>;

@UntilDestroy()
@Injectable()
export class IntegratedSearchProjectsDataAccessCategoriesRepository {
  readonly categories$: Observable<ISCategoryDetails[]>;
  readonly loading$: Observable<boolean>;
  readonly refreshing$: Observable<boolean>;
  readonly error$: Observable<string | null>;

  get currentProjectId(): number | null {
    return this.__store.getValue().currentProjectId;
  }

  get categories(): ISCategoryDetails[] {
    return this.__store.query(getAllEntities());
  }

  private readonly __store: Store;

  constructor() {
    this.__store = this.createStore();
    this.categories$ = this.__store.pipe(selectAllEntities(), shareReplay({ refCount: true, bufferSize: 1 }));
    this.loading$ = this.__store.pipe(select((state) => state.loading));
    this.refreshing$ = this.__store.pipe(select((state) => state.refreshing));
    this.error$ = this.__store.pipe(select((state) => state.error));
  }

  setLoading(refreshing?: boolean): void {
    this.__store.update(
      setProps({
        loading: !refreshing,
        refreshing,
        error: null,
      }),
    );
  }

  resetStore(): void {
    this.__store.reset();
  }

  saveCategories(currentProjectId: number, categories: ISCategoryDetails[]): void {
    this.__store.update(
      setEntities(categories),
      setProps({
        loading: false,
        refreshing: false,
        currentProjectId,
      }),
    );
  }

  handleLoadCategoriesFail(): void {
    this.__store.update(
      deleteAllEntities(),
      setProps({
        loading: false,
        refreshing: false,
        error:
          'Something went wrong during loading categories for this project. Please refresh the page or try again later.',
        currentProjectId: null,
      }),
    );
  }

  updateCategories(categories: ISCategoryDetails[]): void {
    this.__store.update(setEntities(categories));
  }

  private createStore(): Store {
    return createStore(
      { name: ISStoreEnum.PROJECTS_CATEGORIES },
      withProps<StoreProps>({
        loading: false,
        refreshing: false,
        error: null,
        currentProjectId: null,
      }),
      withEntities<ISCategoryDetails, 'category_id'>({ idKey: 'category_id' }),
    );
  }
}
